﻿body {
 
}
footer {
    .horizontal-list{
        padding-right: 20px;
    }
    a{
        color:#fff;
        text-decoration:underline;
    }
}


.footer-section{
	margin-bottom: $small-margin; 
}