/**
 * @name Forms
 * @order 3
 */

/**
 * @name Input fields
 * @state :focus - Focussed state
 * @state :hover - Hover state
 * @state .error - Error state
 * @markup
 * <form><label>Label:</label> <input type="text" class="{{{escaped}}}"></form>
 */

$form-font-size:		$base-font-size !default;
$form-font-family:		$base-font-family !default;
$form-text-color:		$text-color !default;
$form-padding:			13px !default;
$form-border-width:		1px !default;
$form-border-color:		#cccccc !default;
$form-focus-border:		#454545 !default;
$form-bg:				transparent !default;
$form-focus-bg:			transparent !default;
$form-border-radius:	2px !default;
$form-border-style:		solid !default;



@mixin form-base{
	font-size: $form-font-size;
	font-family: $form-font-family;
	padding: $form-padding;
	background-color: $form-bg;
	border-width: $form-border-width;
	border-color: $form-border-color;
	border-radius: $form-border-radius;
	border-style: $form-border-style;
	color: $form-text-color;
	margin-bottom:15px;
	&:focus, &:hover{
		outline: 0;
		border-color: $form-focus-border;
		background-color: $form-focus-bg;
	}
	&.large{}

}


textarea, input:not([type='submit']):not([type='button']):not([type='checkbox']):not([type='radio']):not([type='reset']) {
	@include form-base;
	width: 100%;
    transition: border-color 200ms;

}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button, 
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
input {
    -webkit-appearance: none;
}
label.FieldLabelTop, .formTitle {
    display:none;
}