﻿@charset "utf-8";

/* reset */
.photoMosaic,
.photoMosaic img, .photoMosaic div, .photoMosaic span,
.photoMosaic a:link, .photoMosaic a:visited, .photoMosaic a:active, .photoMosaic a:hover {
    background:none; outline:none; border:0; height:auto; width:auto; max-height:none !important; max-width:none !important; 
    min-height:0 !important; min-width:0 !important; font-style:normal; font-variant:normal; font-weight:normal;
    list-style:none; margin:0; padding:0; position:static; float:none; visibility:visible;
    overflow:visible; color:#000000; letter-spacing:normal; line-height:120%; text-align:left; text-decoration:none; 
    text-indent:0; text-transform:none; vertical-align:baseline; white-space:normal; word-spacing:normal
}

/* selective reset */
.photoMosaic img, .photoMosaic a:link, .photoMosaic a:visited, .photoMosaic a:active, .photoMosaic a:hover {
    margin:0 !important; padding:0 !important;
}

/* base */
.photoMosaic { position:relative; overflow:hidden;} 
    .photoMosaic span,
    .photoMosaic a:link,
    .photoMosaic a:visited,
    .photoMosaic a:active,
    .photoMosaic a:hover { display:block; overflow:hidden; position:absolute; }

    .photoMosaic img { display:block; position:relative; top:0px; opacity:1; }
        .photoMosaic a:hover img { opacity:0.8; }

        .photoMosaic img.thumbnail,
        .photoMosaic img.medium,
        .photoMosaic img.large { position:absolute; opacity:0;}

/* loading */
.photoMosaic .photoMosaicLoading { background:transparent url('/../../../../Kjei/Skin/Icons/PhotoMosaic/spinner-arrows.gif') 0px 0px no-repeat; padding:0 0 0 20px; line-height:20px; }


.photoMosaic.loading .photomosaic-spinner { background:#efefef url('../../../../Kjei/Skin/Icons/PhotoMosaic/spinner-snake.gif') 50% 50% no-repeat; }
.photoMosaic.loading .photomosaic-item.loaded .photomosaic-spinner { display:none; }

.PM_preloadify { visibility:hidden; position:absolute; bottom:5px; right:10px; }

.PM_js .photoMosaicTarget .gallery { display:none; }


/* overflow:hidden; on a container kills 3d */
/* base */
.PM_csstransforms.PM_csstransitions .photoMosaic .photomosaic-item,
.PM_csstransforms.PM_csstransitions .photoMosaic img {
    -webkit-transform-origin: center center;
       -moz-transform-origin: center center; 
        -ms-transform-origin: center center;
         -o-transform-origin: center center;
            transform-origin: center center;

    -webkit-transition: all 0.3s ease-out 0s;
       -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
         -o-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s;

    -webkit-transform-style: preserve-3d;
       -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
         -o-transform-style: preserve-3d;
            transform-style: preserve-3d;

    -webkit-backface-visibility: visible;
       -moz-backface-visibility: visible;
        -ms-backface-visibility: visible;
         -o-backface-visibility: visible;
            backface-visibility: visible;

    -webkit-transform: scale(1);
       -moz-transform: scale(1);
        -ms-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);
}

/* The .loaded is added after all of the images have loaded */
.PM_csstransforms.PM_csstransitions .photoMosaic.resize-transition-none .photomosaic-item,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-none img,
.PM_csstransforms.PM_csstransitions .photoMosaic.resize-transition-none .loaded img {
    -webkit-transition: none;
       -moz-transition: none;
        -ms-transition: none;
         -o-transition: none;
            transition: none;
}

.PM_csstransforms.PM_csstransitions .photoMosaic.transition-none .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-none .loading img { }

.PM_csstransforms.PM_csstransitions .photoMosaic.transition-custom .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-custom .loading img {
    /* good for example
        opacity:0;
        -webkit-transform: rotate( -5deg ) scale( 0.95 );
           -moz-transform: rotate( -5deg ) scale( 0.95 );
            -ms-transform: rotate( -5deg ) scale( 0.95 );
             -o-transform: rotate( -5deg ) scale( 0.95 );
                transform: rotate( -5deg ) scale( 0.95 );
    */
}

.PM_csstransforms.PM_csstransitions .photoMosaic.transition-fade .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-fade .loading img {
    opacity: 0;
}

/*.PM_csstransforms.PM_csstransitions .photoMosaic.transition-scale-up .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-scale-up .loading img {
    opacity: 0;
       -moz-transform: scale( 0.95 );
        -ms-transform: scale( 0.95 );
         -o-transform: scale( 0.95 );
            transform: scale( 0.95 );
}

.PM_csstransforms.PM_csstransitions .photoMosaic.transition-scale-down .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-scale-down .loading img {
    opacity: 0;
    -webkit-transform: scale( 1.15 );
       -moz-transform: scale( 1.15 );
        -ms-transform: scale( 1.15 );
         -o-transform: scale( 1.15 );
            transform: scale( 1.15 );
}*/

.PM_csstransforms.PM_csstransitions .photoMosaic.transition-slide-up .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-slide-up .loading img {
    opacity: 0;
    -webkit-transform: translateY( 5% );
       -moz-transform: translateY( 5% );
        -ms-transform: translateY( 5% );
         -o-transform: translateY( 5% );
            transform: translateY( 5% );
}

.PM_csstransforms.PM_csstransitions .photoMosaic.transition-slide-down .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-slide-down .loading img {
    opacity: 0;
    -webkit-transform: translateY( -5% );
       -moz-transform: translateY( -5% );
        -ms-transform: translateY( -5% );
         -o-transform: translateY( -5% );
            transform: translateY( -5% );
}

.PM_csstransforms.PM_csstransitions .photoMosaic.transition-slide-left .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-slide-left .loading img {
    opacity: 0;
    -webkit-transform: translateX( 5% );
       -moz-transform: translateX( 5% );
        -ms-transform: translateX( 5% );
         -o-transform: translateX( 5% );
            transform: translateX( 5% );
}

.PM_csstransforms.PM_csstransitions .photoMosaic.transition-slide-right .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-slide-right .loading img {
    opacity: 0;
    -webkit-transform: translateX( -5% );
       -moz-transform: translateX( -5% );
        -ms-transform: translateX( -5% );
         -o-transform: translateX( -5% );
            transform: translateX( -5% );
}

/* START PrettyPhoto */
/* ------------------------------------------------------------------------
	This you can edit.
------------------------------------------------------------------------- */

	/* ---------------------------------- 
		Default Theme
	----------------------------------- */

	div.pp_default .pp_top, 
	div.pp_default .pp_top .pp_middle,
	div.pp_default .pp_top .pp_left,
	div.pp_default .pp_top .pp_right,
	div.pp_default .pp_bottom,
	div.pp_default .pp_bottom .pp_left,
	div.pp_default .pp_bottom .pp_middle,
	div.pp_default .pp_bottom .pp_right { height: 13px; }
	
	/*div.pp_default .pp_top .pp_left { background: url(../prettyphoto/default/sprite.png) -78px -93px no-repeat; } /* Top left corner */
    div.pp_default .pp_top .pp_left { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) -78px -93px no-repeat; } /* Top left corner */
	div.pp_default .pp_top .pp_middle { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite_x.png) top left repeat-x; } /* Top pattern/color */
	div.pp_default .pp_top .pp_right { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) -112px -93px no-repeat; } /* Top right corner */
	
	div.pp_default .pp_content .ppt { color: #f8f8f8; }
	div.pp_default .pp_content_container .pp_left { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite_y.png) -7px 0 repeat-y; padding-left: 13px; }
	div.pp_default .pp_content_container .pp_right { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite_y.png) top right repeat-y; padding-right: 13px; }
	div.pp_default .pp_content { background-color: #fff; } /* Content background */
	div.pp_default .pp_next:hover { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite_next.png) center right  no-repeat; cursor: pointer; } /* Next button */
	div.pp_default .pp_previous:hover { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite_prev.png) center left no-repeat; cursor: pointer; } /* Previous button */
	div.pp_default .pp_expand { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) 0 -29px no-repeat; cursor: pointer; width: 28px; height: 28px; } /* Expand button */
	div.pp_default .pp_expand:hover { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) 0 -56px no-repeat; cursor: pointer; } /* Expand button hover */
	div.pp_default .pp_contract { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) 0 -84px no-repeat; cursor: pointer; width: 28px; height: 28px; } /* Contract button */
	div.pp_default .pp_contract:hover { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) 0 -113px no-repeat; cursor: pointer; } /* Contract button hover */
	div.pp_default .pp_close { width: 30px; height: 30px; background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) 2px 1px no-repeat; cursor: pointer; } /* Close button */
	div.pp_default #pp_full_res .pp_inline { color: #000; } 
	div.pp_default .pp_gallery ul li a { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/default_thumb.png) center center #f8f8f8; border:1px solid #aaa; }
	div.pp_default .pp_gallery ul li a:hover,
	div.pp_default .pp_gallery ul li.selected a { border-color: #fff; }
	div.pp_default .pp_social { margin-top: 7px; }

	div.pp_default .pp_gallery a.pp_arrow_previous,
	div.pp_default .pp_gallery a.pp_arrow_next { position: static; left: auto; }
	div.pp_default .pp_nav .pp_play,
	div.pp_default .pp_nav .pp_pause { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) -51px 1px no-repeat; height:30px; width:30px; }
	div.pp_default .pp_nav .pp_pause { background-position: -51px -29px; } 
	div.pp_default .pp_details { position: relative; }
	div.pp_default a.pp_arrow_previous,
	div.pp_default a.pp_arrow_next { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) -31px -3px no-repeat; height: 20px; margin: 4px 0 0 0; width: 20px; }
	div.pp_default a.pp_arrow_next { left: 52px; background-position: -82px -3px; } /* The next arrow in the bottom nav */
	div.pp_default .pp_content_container .pp_details { margin-top: 5px; }
	div.pp_default .pp_nav { clear: none; height: 30px; width: 110px; position: relative; }
	div.pp_default .pp_nav .currentTextHolder{ font-family: Georgia; font-style: italic; color:#999; font-size: 11px; left: 75px; line-height: 25px; margin: 0; padding: 0 8px 0 8px; }
	
	div.pp_default .pp_close:hover, div.pp_default .pp_nav .pp_play:hover, div.pp_default .pp_nav .pp_pause:hover, div.pp_default .pp_arrow_next:hover, div.pp_default .pp_arrow_previous:hover { opacity:0.7; }

	div.pp_default .pp_description{ font-size: 11px; font-weight: bold; line-height: 14px; margin: 5px 50px 5px 0; }

	div.pp_default .pp_bottom .pp_left { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) -78px -127px no-repeat; } /* Bottom left corner */
	div.pp_default .pp_bottom .pp_middle { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite_x.png) bottom left repeat-x; } /* Bottom pattern/color */
	div.pp_default .pp_bottom .pp_right { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) -112px -127px no-repeat; } /* Bottom right corner */

	div.pp_default .pp_loaderIcon { background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/loader.gif) center center no-repeat; } /* Loader icon */

/* ------------------------------------------------------------------------
	DO NOT CHANGE
------------------------------------------------------------------------- */

	div.pp_pic_holder a:focus { outline:none; }

	div.pp_overlay {
		/*position: absolute;*/
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #000;
		display: none;
		width: 100%;
		z-index: 9500;
	}
	
	div.pp_pic_holder {
		display: none;
		position: absolute;
		width: 100px;
		z-index: 10000;
	}

		
		.pp_top {
			height: 20px;
			position: relative;
		}
			* html .pp_top { padding: 0 20px; }
		
			.pp_top .pp_left {
				height: 20px;
				left: 0;
				position: absolute;
				width: 20px;
			}
			.pp_top .pp_middle {
				height: 20px;
				left: 20px;
				position: absolute;
				right: 20px;
			}
				* html .pp_top .pp_middle {
					left: 0;
					position: static;
				}
			
			.pp_top .pp_right {
				height: 20px;
				left: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: 20px;
			}
		
		.pp_content { height: 40px; min-width: 40px; }
		* html .pp_content { width: 40px; }
		
		.pp_fade { display: none; }
		
		.pp_content_container {
			position: relative;
			text-align: left;
			width: 100%;
		}
		
			.pp_content_container .pp_left { padding-left: 20px; }
			.pp_content_container .pp_right { padding-right: 20px; }
		
			.pp_content_container .pp_details {
				float: left;
				margin: 10px 0 2px 0;
			}
				.pp_description {
					display: none;
					margin: 0;
				}
				
				.pp_social { float: left; margin: 0; }
				.pp_social .facebook { float: left; margin-left: 5px; width: 55px; overflow: hidden; }
				.pp_social .twitter { float: left; }
				
				.pp_nav {
					clear: right;
					float: left;
					margin: 3px 10px 0 0;
				}
				
					.pp_nav p {
						float: left;
						margin: 2px 4px;
						white-space: nowrap;
					}
					
					.pp_nav .pp_play,
					.pp_nav .pp_pause {
						float: left;
						margin-right: 4px;
						text-indent: -10000px;
					}
				
					a.pp_arrow_previous,
					a.pp_arrow_next {
						display: block;
						float: left;
						height: 15px;
						margin-top: 3px;
						overflow: hidden;
						text-indent: -10000px;
						width: 14px;
					}
		
		.pp_hoverContainer {
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 2000;
		}
		
		.pp_gallery {
			display: none;
			left: 50%;
			margin-top: -50px;
			position: absolute;
			z-index: 10000;
		}
		
			.pp_gallery div {
				float: left;
				overflow: hidden;
				position: relative;
			}
			
			.pp_gallery ul {
				float: left;
				height: 35px;
				margin: 0 0 0 5px;
				padding: 0;
				position: relative;
				white-space: nowrap;
			}
			
			.pp_gallery ul a {
				border: 1px #000 solid;
				border: 1px rgba(0,0,0,0.5) solid;
				display: block;
				float: left;
				height: 33px;
				overflow: hidden;
			}
			
			.pp_gallery ul a:hover,
			.pp_gallery li.selected a { border-color: #fff; }
			
			.pp_gallery ul a img { border: 0; }
			
			.pp_gallery li {
				display: block;
				float: left;
				margin: 0 5px 0 0;
				padding: 0;
			}
			
			.pp_gallery li.default a {
				background: url(../prettyphoto/facebook/default_thumbnail.gif) 0 0 no-repeat;
				display: block;
				height: 33px;
				width: 50px;
			}
			
			.pp_gallery li.default a img { display: none; }
			
			.pp_gallery .pp_arrow_previous,
			.pp_gallery .pp_arrow_next {
				margin-top: 7px !important;
			}
		
		a.pp_next {
			background: url(../prettyphoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
			display: block;
			float: right;
			height: 100%;
			text-indent: -10000px;
			width: 49%;
		}
			
		a.pp_previous {
			background: url(../../prettyphoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
			display: block;
			float: left;
			height: 100%;
			text-indent: -10000px;
			width: 49%;
		}
		
		a.pp_expand,
		a.pp_contract {
			cursor: pointer;
			display: none;
			height: 20px;	
			position: absolute;
			right: 30px;
			text-indent: -10000px;
			top: 10px;
			width: 20px;
			z-index: 20000;
		}
			
		a.pp_close {
			position: absolute; right: 0; top: 0; 
			display: block;
			line-height:22px;
			text-indent: -10000px;
		}
		
		.pp_bottom {
			height: 20px;
			position: relative;
		}
			* html .pp_bottom { padding: 0 20px; }
			
			.pp_bottom .pp_left {
				height: 20px;
				left: 0;
				position: absolute;
				width: 20px;
			}
			.pp_bottom .pp_middle {
				height: 20px;
				left: 20px;
				position: absolute;
				right: 20px;
			}
				* html .pp_bottom .pp_middle {
					left: 0;
					position: static;
				}
				
			.pp_bottom .pp_right {
				height: 20px;
				left: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: 20px;
			}
		
		.pp_loaderIcon {
			display: block;
			height: 24px;
			left: 50%;
			margin: -12px 0 0 -12px;
			position: absolute;
			top: 50%;
			width: 24px;
		}
		
		#pp_full_res {
			line-height: 1 !important;
		}
		
			#pp_full_res .pp_inline {
				text-align: left;
			}
			
				#pp_full_res .pp_inline p { margin: 0 0 15px 0; }
	
		div.ppt {
			color: #fff;
			display: none;
			/*font-size: 17px;*/
			font-size: 0px;
			/*margin: 0 0 5px 15px;*/
			margin: 0;
			z-index: 9999;
		}
/*END PrettyPhoto*/