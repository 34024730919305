﻿@import "Modules/Helpers/functions";

$site-max-width: 82em;


// Defined colors
$black: #191919;
$white: #ffffff;
$dark-gray:#6d6e71; 
$soft-gray:#a7a7a7;
$light-gray:#cbcbcb; 
$blue:#004b72;
$red:#7d1a20;
$green: #006346;


$dark-blue:#324469;
$dark-green:#536F22;
 
// Text colors 
$text-color: $black;
$quiet-color: $text-color; 
$loud-color: $text-color;  
$heading-color: $black;   
$alternate-heading-color: $white; 
$link-color: $red;  
$link-hover-color: $blue;

// Button colors
$primary-button: $blue;
$secondary-button: $white;

// Harmon settings
// Se: http://www.modularscale.com/ för olika ratios
$base:  20px !default; 
$ratio: $minor-third !default; 
 

// Typography
// --- Body
$base-font-size: 1em;
$base-line-height: 1.35em;
$base-font-family: "Museo Sans", Calibri, Candara, Segoe UI, Optima, Arial, sans-serif;
$base-font-weight: 500;
$base-font-color: $text-color;
$bold-font-family: "Museo Sans", Calibri, Candara, Segoe UI, Optima, Arial, sans-serif; 
$italic-font-family: "Museo Sans", Calibri, Candara, Segoe UI, Optima, Arial, sans-serif;
// --- Headings
$heading-font-family: "freight-text-pro", Calibri, Candara, Segoe UI, Optima, Arial, sans-serif;
$heading-font-weight: 700; 
$heading-font-color: $heading-color;
 


// Buttons settings
$border-radius: 0px;
$button-color: white !default;
$button-font: $base-font-family;
$button-font-size: 1em;
$button-weight: regular;
$button-line-height: 1.2em;
$button-text-align: center !default;
$button-bg-color: $primary-button !default;
$button-margin-bottom: 0;
$button-display: inline-block;


// Forms settings

$form-font-size: 18px;
$form-font-family: $base-font-family;
$form-padding: 13px;
$form-border-width: 2px;
$form-border-color: $soft-gray;
$form-border-radius: 0px;
$form-border-style: solid;
$form-text-color: $text-color;
$form-focus-bg: white;
$form-focus-border: tomato;
$form-bg: white;

// Margins & Paddings
// These are used throughout the site to be consistent.
$large-padding: 60px;
$medium-padding: 40px;
$small-padding: 20px;
$tiny-padding: 10px;

$large-margin: 60px;
$medium-margin: 40px;
$small-margin: 20px;
$tiny-margin: 10px;

// Site specific variables:
$circle-diameter: 70px;
$circle-offset: 20px;
$default-border: 1px solid $black;

// Neat Settings
// Must appear above the import of Neat.
$grid-columns: 12 !default;
$visual-grid: false !default;
$visual-grid-color: yellow !default;
$visual-grid-index: front !default;
$visual-grid-opacity: 0.3 !default;

@import "Vendor/index";
@import "Modules/index";